import React from "react";

function PageNotFound() {
  return (
    <h1 className=" text-5xl text-white   font-bold">
      Page not Found<span className="text-orange-400"> 404</span> :(
    </h1>
  );
}

export default PageNotFound;
